exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activate-premium-tsx": () => import("./../../../src/pages/activate-premium.tsx" /* webpackChunkName: "component---src-pages-activate-premium-tsx" */),
  "component---src-pages-activate-tsx": () => import("./../../../src/pages/activate.tsx" /* webpackChunkName: "component---src-pages-activate-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../../../src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-confirmation-expired-tsx": () => import("./../../../src/pages/confirmation-expired.tsx" /* webpackChunkName: "component---src-pages-confirmation-expired-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-forgot-tsx": () => import("./../../../src/pages/forgot.tsx" /* webpackChunkName: "component---src-pages-forgot-tsx" */),
  "component---src-pages-install-tsx": () => import("./../../../src/pages/install.tsx" /* webpackChunkName: "component---src-pages-install-tsx" */),
  "component---src-pages-join-trial-tsx": () => import("./../../../src/pages/join-trial.tsx" /* webpackChunkName: "component---src-pages-join-trial-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-recovery-tsx": () => import("./../../../src/pages/recovery.tsx" /* webpackChunkName: "component---src-pages-recovery-tsx" */),
  "component---src-pages-register-trial-tsx": () => import("./../../../src/pages/register/trial.tsx" /* webpackChunkName: "component---src-pages-register-trial-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-tsx": () => import("./../../../src/pages/reset.tsx" /* webpackChunkName: "component---src-pages-reset-tsx" */),
  "component---src-pages-ri-tsx": () => import("./../../../src/pages/ri.tsx" /* webpackChunkName: "component---src-pages-ri-tsx" */),
  "component---src-pages-session-expired-tsx": () => import("./../../../src/pages/session-expired.tsx" /* webpackChunkName: "component---src-pages-session-expired-tsx" */),
  "component---src-pages-share-tsx": () => import("./../../../src/pages/share.tsx" /* webpackChunkName: "component---src-pages-share-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */)
}

